import React, { useEffect, useState } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "styled-components"
import { Heading, theming } from "@staccx/base"
import StaccCredit from "./StaccCredit"
import colors from "../lib/colors"

const PageLayout = ({
  children,
  onHideNav,
  onShowNav,
  showNav,
  siteTitle,
  path,
  colorsCallback = () => null
}) => {
  const [pageColors, setPageColors] = useState([
    theming.color.bg,
    theming.color.text
  ])

  useEffect(() => {
    if (path) {
      setPageColors(
        colors[Math.floor(Math.random() * Math.floor(colors.length))]
      )
    }
  }, [path])

  useEffect(() => {
    colorsCallback(pageColors)
  }, [pageColors])

  return (
    <SiteOuter bgColor={pageColors[0]} textColor={pageColors[1]}>
      <SiteInner>
        <StyledLink fade to="/">
          <SiteLogo level={1}>{siteTitle}</SiteLogo>
        </StyledLink>
        {children}
        <StaccCredit />
      </SiteInner>
    </SiteOuter>
  )
}

const SiteOuter = styled.div`
  background-color: ${p => p.bgColor};
  color: ${p => p.textColor};
  *::selection {
    color: ${p => p.bgColor};
    background-color: ${p => p.textColor}fe;
  }
  padding: 36px 36px 0;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 20px 20px 0;
    height: 100vh;
    margin: 0;
    overflow-x: hidden;
    overflow: -moz-scrollbars-none;
  }
`

const SiteInner = styled.div`
  position: relative;
  @media (max-width: 768px) {
    overflow-x: -moz-hidden-unscrollable;
  }
`

const SiteLogo = styled(Heading)`
  display: inline-block;
  font-family: ${theming.fontFamily.heading};
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  line-height: 0.9;
  position: relative;
  z-index: 1;
  user-select: none;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const StyledLink = styled(AniLink)`
  text-decoration: none;
`

export default PageLayout
