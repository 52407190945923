import React from "react"
import styled from "styled-components"

const IconLogo = ({ className }) => (
  <SVG
    width="22"
    height="24"
    viewBox="0 0 22 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9617 0.422913L21.3464 6.3173V18.067L10.9617 23.9614L0.577148 18.067V6.3173L10.9617 0.422913ZM2.29068 17.1281V13.6341L10.1049 18.0673V21.5636L2.29068 17.1281ZM10.1049 16.156L2.29068 11.7228V8.22866L10.1049 12.6619V16.156ZM11.8184 21.5637V12.6622L14.8704 10.9308V19.8314L11.8184 21.5637ZM16.5839 18.8588L19.6328 17.1281V8.2289L16.5839 9.95862V18.8588ZM14.0435 4.08361L10.9617 2.33438L3.11778 6.7867L6.19833 8.53437L14.0435 4.08361ZM15.7275 5.03946L7.88283 9.48998L10.962 11.2368L18.8059 6.78679L15.7275 5.03946Z"
    />
  </SVG>
)

const SVG = styled.svg`
  fill: currentColor;
`

export default IconLogo
