export default [
  ["#e6dad7", "#1c795b"],
  ["#fac1bc", "#281859"],
  ["#1c4c5e", "#c7dadc"],
  ["#f2cf69", "#387577"],
  ["#343741", "#aac8d8"],
  ["#f4e987", "#616a54"],
  ["#f2c6b6", "#5c3956"],
  ["#ffcbb5", "#20704c"],
  ["#f8c5c9", "#301358"],
  ["#341672", "#d7efe1"],
  ["#bfcdda", "#4b7052"],
  ["#daefcd", "#43866a"],
  ["#44665f", "#fec3c3"],
  ["#ebded4", "#667858"],
  ["#392d66", "#e5d8cc"],
  ["#533352", "#d9baac"],
  ["#f8d3c1", "#24788b"],
  ["#f7df9c", "#52706a"],
  ["#f9b2b9", "#1b516c"],
  ["#544252", "#d5d279"],
  ["#4b394f", "#daebc4"],
  ["#31224b", "#f8d5c4"],
  ["#d8d9c8", "#392588"],
  ["#fea088", "#574a5d"],
  ["#1e777f", "#ffd993"],
  ["#d0cbc6", "#3f2155"],
  ["#26556e", "#e2e285"],
  ["#f4aeb6", "#0b2be2"]
]
