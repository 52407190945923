import React from "react"
import styled from "styled-components"
import { theming } from "@staccx/base"
import IconLogo from "../components/icon/IconLogo"

const StaccCredit = () => (
  <Link href="https://stacc.com/">
    <StyledDiv>
      <StyledIconLogo />A Stacc Project
    </StyledDiv>
  </Link>
)

const StyledIconLogo = styled(IconLogo)`
  margin-right: ${theming.spacing.small};
`

const StyledDiv = styled.div`
  position: fixed;
  font-size: ${theming.font.tiny};
  text-transform: uppercase;
  letter-spacing: 0.2em;
  bottom: 36px;
  transform-origin: bottom left;
  transform: rotate(-90deg) translateY(100%);
  display: flex;
  align-items: center;
  user-select: none;
`

export default StaccCredit

const Link = styled.a`
  text-decoration: none;
`
